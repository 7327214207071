import {
  ALERT,
  ALERT_TYPE,
  COLOR_ALERT,
  AUTHORITY,
  AUTHORITY_TXT,
  APPROVAL_STATUS,
  APPROVAL_STATUS_TXT,
  NOTICE_TYPE_COLOR,
  FORMAT_RESIDENCE_CARD_NUMBER,
  REGEX_FULLSIZE,
  FORMAT_DATE,
  REGEX_DATE_NONE,
  REGEX_DATE_HYPHEN,
  REGEX_DATE_SLASH,
  DATA_UPDATE_STATUS,
  REQUEST_TEXT_LIST,
  REG_STATUS,
  REGEX_UPPER_ALPHABET_SPACE,
  REQUEST_TEXT_OLD_LIST,
} from "@/common/constants";

export default {
  getAlertId(alert) {
    switch (alert) {
      case "Warning":
        return ALERT_TYPE.ALERT_WARNING;
      case "Attention Needed":
        return ALERT_TYPE.ALERT_ATTENTION_NEEDED;
      case "Update Needed":
        return ALERT_TYPE.ALERT_UPDATE_NEEDED;
      case "Missing Information":
        return ALERT_TYPE.ALERT_MISSING_INFORMATION;
    }
  },

  getAlertType(alert) {
    switch (alert) {
      case ALERT.ALERT_SPECIFICATION_EXPIRED:
      case ALERT.ALERT_INAPPROPRIATE_VISA_TYPE:
      case ALERT.ALERT_INVALID_RESIDENCE_CARD:
      case ALERT.ALERT_MISSING_RELATED_DOCUMENT:
      case ALERT.ALERT_MISSING_RELATED_DOCUMENT_TWO:
        return ALERT_TYPE.ALERT_WARNING;

      case ALERT.ALERT_PAY_ATTENTION:
      case ALERT.ALERT_TBD:
      case ALERT.ALERT_NEED_TO_CONFIRM_EMPLOYMENT_RESTRICTION:
        return ALERT_TYPE.ALERT_ATTENTION_NEEDED;

      case ALERT.WITHIN_THREE_MONTHS:
      case ALERT.WITHIN_TWO_MONTHS:
      case ALERT.WITHIN_ONE_MONTHS:
      case ALERT.WITHIN_TWO_WEEKS:
      case ALERT.WITHIN_ONE_WEEKS:
      case ALERT.WITHIN_THREE_MONTHS_EXTENDED:
      case ALERT.WITHIN_TWO_MONTHS_EXTENDED:
      case ALERT.WITHIN_ONE_MONTHS_EXTENDED:
      case ALERT.WITHIN_TWO_WEEKS_EXTENDED:
      case ALERT.WITHIN_ONE_WEEKS_EXTENDED:
        return ALERT_TYPE.ALERT_UPDATE_NEEDED;

      case ALERT.ALERT_SAVED_TEMPORARY:
      case ALERT.ALERT_HAS_REQUEST:
        return ALERT_TYPE.ALERT_MISSING_INFORMATION;
    }
  },

  validateResidenceCardNumber(value) {
    return value.match(FORMAT_RESIDENCE_CARD_NUMBER);
  },

  validateRegexFullsize(value) {
    return value.match(REGEX_FULLSIZE);
  },

  validateUppeerAlphabetSpace(value) {
    return !value.match(REGEX_UPPER_ALPHABET_SPACE);
  },

  validateFormatDate(value) {
    return value.match(FORMAT_DATE);
  },

  validateDateNone(value) {
    return value.match(REGEX_DATE_NONE);
  },

  validateDate(value) {
    return (
      value.match(REGEX_DATE_NONE) ||
      value.match(REGEX_DATE_HYPHEN) ||
      value.match(REGEX_DATE_SLASH) ||
      value.match(FORMAT_DATE)
    );
  },

  // get authority by text
  getAuthorityByTxt(authorityTxt) {
    if (authorityTxt === AUTHORITY_TXT["0"]) {
      return AUTHORITY.MASTER;
    }
    if (authorityTxt === AUTHORITY_TXT["1"]) {
      return AUTHORITY.GENERAL;
    }
    if (authorityTxt === AUTHORITY_TXT["2"]) {
      return AUTHORITY.ADMIN;
    }
    if (authorityTxt === AUTHORITY_TXT["3"]) {
      return AUTHORITY.EDITOR;
    }
  },

  // translate status into japanese
  getApprovalCurrentStatus(status) {
    switch (status) {
      case APPROVAL_STATUS["APPROVED_FINAL"]:
        return APPROVAL_STATUS_TXT.APPROVED_FINAL;
      case APPROVAL_STATUS["APPROVED"]:
        return APPROVAL_STATUS_TXT.APPROVED;
      case APPROVAL_STATUS["REJECTED"]:
        return APPROVAL_STATUS_TXT.REJECTED;
      case APPROVAL_STATUS["CANCELLED"]:
        return APPROVAL_STATUS_TXT.CANCELLED;
      case APPROVAL_STATUS["REQUESTED"]:
        return APPROVAL_STATUS_TXT.REQUESTED;
      case APPROVAL_STATUS["NOT_REQUESTED"]:
        return APPROVAL_STATUS_TXT.NOT_REQUESTED;
      default:
        return status;
    }
  },

  // translate section into japanese
  getIncompleteSections(incompleteSections) {
    return incompleteSections.map((section) => {
      switch (section) {
        case "basic_info":
          return "基本情報";
        case "residence_card_info":
          return "在留カード情報";
        case "other_materials":
        return "その他資料";
        default:
          return section;
      }
    }
    );
  },

  // 多国語対応が不要な日本語データはConstFuncを使う
  // 多国語選択によって変わるデータは、i18nで対応
  getPaymentPattern(pattern) {
    switch (pattern) {
      case "pay use":
        return "従量課金";
      case "min unit":
        return "最低利用料金＋単価テーブル";
      case "flat rate":
        return "定額テーブル";
    }
  },

  getPaymentType(type) {
    if (type === "unit") {
      return "1人当たり単価";
    }
    return "固定額";
  },

  getCurrentStatus(currentStatus) {
    switch (currentStatus) {
      case "Working":
        return "入社";
      case "Hired":
        return "入社待ち";
      case "Resigned":
        return "退職";
      case "Suspended":
        return "停職";
      case "Status update needed":
        return "要変更";
      case "Reject Offer":
        return "入社辞退";
      default:
        return "";
    }
  },

  getStaffType(staffType) {
    switch (staffType) {
      case "Regular":
        return "正社員";
      case "Contract":
        return "契約社員";
      case "Part":
        return "パート";
      case "Arbeit":
        return "アルバイト";
      case "Status Temporary needed":
        return "派遣社員";
      case "Others":
        return "その他";
      default:
        return "";
    }
  },

  getDataInputStatus(dataInputStatus) {
    if (dataInputStatus === "Pending") {
      return "入力待ち";
    } else if (dataInputStatus === "Finished") {
      return "入力済";
    }
  },

  getUpdateStage(updateStage) {
    if (updateStage === "Applying") {
      return "在留期間更新許可申請中";
    } else if (updateStage === "Updated") {
      return "更新済み";
    } else {
      return "未申請";
    }
  },

  getRegType(regType) {
    if (regType === "New") {
      return "新規在留カード";
    } else if (regType === "Renew") {
      return "更新在留カード";
    }
  },

  getInsurance(insurance) {
    switch (insurance) {
      case "Unconfirmed":
        return "未確認";
      case "Unknown":
        return "-";
      case "Target":
        return "加入";
      case "Non-target":
        return "未加入";
      default:
        return "-";
    }
  },

  getAlert(alert) {
    switch (alert) {
      case "expired":
        return "期限切れ";
      case "Inappropriate Visa Type":
        return "資格外活動";
      case "Invalid Residence Card":
        return "失効在留カード";
      case "Missing Related Document":
        return "指定書未提出";
      case "Missing Related Document 2":
        return "指定書再提出";
      case "Need to confirm employment restriction":
        return "就労資格対象者";
      case "Pay Attention":
        return "在留カード番号重複";
      case "TBD":
        return "入社日未定";
      case "Warning":
        return "警告";
      case "Within 3 months":
        return "３か月以内";
      case "Within 2 months":
        return "２か月以内";
      case "Within 1 months":
        return "１か月以内";
      case "Within 2 weeks":
        return "２週間以内";
      case "Within 1 week":
        return "１週間以内";
      case "Within 3 months (Extended)":
        return "【延長後】３か月以内";
      case "Within 2 months (Extended)":
        return "【延長後】２か月以内";
      case "Within 1 months (Extended)":
        return "【延長後】１か月以内";
      case "Within 2 weeks (Extended)":
        return "【延長後】２週間以内";
      case "Within 1 week (Extended)":
        return "【延長後】１週間以内";
      case "Attention Needed":
        return "要確認";
      case "Update Needed":
        return "要更新";
      case "Missing Information":
        return "不備あり";
      case "Saved temporary":
        return "一時保存中";
      case "Not assigned":
        return "店舗未所属";
      case "Has request":
        return "リクエスト中";
      case "None Alert":
        return "無";
      default:
        return "";
    }
  },

  getThreeStepsAlerts(alerts) {
    return alerts.map((alert) => {
      switch (alert) {
        case "expired":
          return `<span class="font-weight-bold">期限切れ</span>：登録されている在留カードは期限が切れています。更新された新しい在留カードが手元にないか従業員に確認してください。※現在更新申請中である場合には、基本情報の「更新種類」より「在留期間更新許可申請中」に変更の上、在留カード情報より更新中エビデンス画像を保存してください。`;
        case "Inappropriate Visa Type":
          return `<span class="font-weight-bold">資格外活動</span>：資格外活動許可の確認ができていません。在留カード裏面のスタンプを従業員に確認してください。`;
        case "Invalid Residence Card":
          return `<span class="font-weight-bold">失効在留カード</span>：この在留カードは有効ではありません。在留資格の変更等がないか従業員に確認してください。`;
        case "Need to confirm employment restriction":
          return `<span class="font-weight-bold">就労資格対象者</span>：原則単純就労が認められていない資格です。雇用が問題ないかどうか、本部のご担当者様に確認の上申請をしてください。※問題ないことが確認できた場合は、在留カード情報の「就労資格確認」を確認済み(問題なし)もしくは確認済み(単純労働)に設定してください。`;
        case "Has request":
          return `<span class="font-weight-bold">リクエスト中</span>：従業員へ画像の再提出リクエスト中です。画像が提出されてから申請をしてください。`; 
        default:
          return "";
      }
    })
  },

  getRequestDocumnet(type) {
    switch (type) {
      case "residenceCardFront":
        return "在留カードオモテ";
      case "residenceCardBack":
        return "在留カードウラ";
      case "workDesignation":
        return "指定書";
      case "passport":
        return "パスポート";
      case "studentCard":
        return "学生証";
      case "certificateLongVacation":
        return "長期休暇の証明書";
      default:
        return type;
    }
  },

  getNoticeType(type) {
    switch (type) {
      case "info":
        return "お知らせ";
      case "update":
        return "アップデート";
      case "bug":
        return "不具合警告";
      case "other":
        return "その他";
      default:
        return type;
    }
  },

  getNoticeTypeColor(type) {
    switch (type) {
      case "info":
        return NOTICE_TYPE_COLOR.INFO;
      case "update":
        return NOTICE_TYPE_COLOR.UPDATE;
      case "bug":
        return NOTICE_TYPE_COLOR.BUG;
      case "other":
        return NOTICE_TYPE_COLOR.OTHER;
    }
  },

  getColorAlert(type) {
    switch (type) {
      case ALERT.ALERT_WARNING_TEXT:
        return COLOR_ALERT.WARNING;
      case ALERT.ALERT_ATTENTION_NEEDED_TEXT:
        return COLOR_ALERT.ATTENTION_NEEDED;
      case ALERT.ALERT_UPDATE_NEEDED_TEXT:
        return COLOR_ALERT.UPDATE_NEEDED;
      case ALERT.ALERT_MISSING_INFORMATION_TEXT:
        return COLOR_ALERT.MISSING_INFORMATION;
    }
  },

  getDataUpdateStatus(status) {
    switch (status) {
      case DATA_UPDATE_STATUS.NOT_YET_UPDATED:
        return "未提出";
      case DATA_UPDATE_STATUS.WAITING_FOR_APPROVAL:
        return "確認前";
      case DATA_UPDATE_STATUS.RE_UPLOAD_NEEDED:
        return "差戻し中";
      case DATA_UPDATE_STATUS.APPLYING:
        return "発行待ち";
      case DATA_UPDATE_STATUS.NORMAL:
        return "対象外";
      default:
        return status;
    }
  },

  getRegStatus(status) {
    switch (status) {
      case REG_STATUS.NEW:
        return "未登録";
      case REG_STATUS.COMPLETE:
        return "登録済み";
      case REG_STATUS.UPDATE:
        return "要更新";
      default:
        return status;
    }
  },

  getCardCheckText(isReported) {
    switch (isReported) {
      case null:
        return "未確認";
      case false:
        return "問題なし";
      case true:
        return "偽造の可能性あり";
      default:
        return isReported;
    }
  },

  // get reject reason text Japanese
  getRejectReasonName(reason) {
    if (reason === null) {
      return "";
    }

    let reStr = "";
    const reasonList = reason.split("$$");

    for (const index in reasonList) {
      if (index !== "0") {
        reStr = reStr + "\n\n";
      }

      let rejectReason = REQUEST_TEXT_LIST.find(
        (el) => el.value === reasonList[index]
      );

      if (rejectReason) {
        reStr = reStr + rejectReason.name;
        continue;
      }

      // 過去の理由対応
      rejectReason = REQUEST_TEXT_OLD_LIST.find(
        (el) => el.value === reasonList[index]
      );

      if (rejectReason) {
        reStr = reStr + rejectReason.name;
        continue;
      }

      reStr = reStr + reasonList[index];
    }

    return reStr;
  },
};
