<template>
  <div
    :class="[
      'pulldown',
      { 'bg-white-red': redBackground && required && !data },
    ]"
    v-if="!forcedSquareLabeled && (!squareLabeled || disabled || readonly)"
  >
    <div class="select" v-click-outside="onOutside">
      <input
        class="select-input"
        v-model="data"
        :class="[{ disabled: disabled }, { 'highlight-field': highlight }]"
        :disabled="disabled"
        readonly
        @focus="!readonly && onFocus()"
      />
      <label :class="['placeholder-label']" v-if="!data" @click="onFocus">
        <span v-html="label || placeholder" />
        <span v-if="required && !disabled" class="required-label">必須</span>
      </label>

      <label class="select-label" :class="{ 'd-none': !data }">
        <span v-html="label || placeholder" />
        <span v-if="required && !disabled" class="required-label">必須</span>
      </label>

      <div class="icon-group">
        <v-icon v-if="showCancel && data" @click="onClear" class="icon-close">
          mdi-close
        </v-icon>
        <v-icon
          v-show="!disabled && !readonly"
          :class="!selecting ? 'icon-down' : 'icon-up'"
          @click="!isMobile && onShowOption"
        >
          mdi-menu-down
        </v-icon>
      </div>
      <div 
      class="data-list" 
      :style="{ maxHeight: listHeight ? `${listHeight}px` : 'auto' }" 
      v-if="selecting && items.length && itemText">
        <div
          class="data"
          v-for="(item, i) in items"
          :key="i"
          @click="selectItem(item)"
        >
          {{ multiLanguage ? $t(item[itemValue]) : item[itemText] }}
        </div>
      </div>
    </div>

    <div class="modal-mask" v-if="modalSelect && !readonly">
      <div class="modal-wrapper">
        <div class="modal-container container bounce-in">
          <div style="max-height: calc(100vh - 210px); overflow-y: auto">
            <div
              class="modal-data"
              v-for="(item, i) in items"
              :key="i"
              @click="selectItem(item)"
            >
              <div class="select-item">
                <span class="name-item">{{
                  multiLanguage ? $t(item[itemValue]) : item[itemText]
                }}</span>
                <span
                  :class="[
                    'radio-check',
                    { 'radio-active': valueSelected == item[itemValue] },
                  ]"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex" v-else>
    <div class="square-label-block">
      <div
        :class="[
          'square-label',
          {
            'square-label-required': required,
            'square-label-optional': !required,
            'lang-en': $store.state.lang == 'en',
            'lang-ja': $store.state.lang == 'ja',
            'lang-kr': $store.state.lang == 'kr',
            'lang-my': $store.state.lang == 'my',
            'lang-np': $store.state.lang == 'np',
            'lang-pr': $store.state.lang == 'pr',
            'lang-sc': $store.state.lang == 'sc',
            'lang-tc': $store.state.lang == 'tc',
            'lang-tg': $store.state.lang == 'tg',
            'lang-vi': $store.state.lang == 'vi',
            'lang-ep': $store.state.lang == 'ep',
          },
        ]"
      >
        <p v-html="required ? $t('required') : $t('optional')" />
      </div>
    </div>

    <div class="pulldown w-100">
      <div class="select" v-click-outside="onOutside">
        <input
          class="select-input"
          v-model="data"
          :class="[{ disabled: disabled }, { 'highlight-field': highlight }]"
          :disabled="disabled"
          readonly
          @focus="!readonly && onFocus()"
        />
        <label :class="['placeholder-label']" v-if="!data" @click="onFocus">
          <span v-html="label || placeholder" />
        </label>

        <label class="select-label" :class="{ 'd-none': !data }">
          <span v-html="label || placeholder" />
        </label>

        <div class="icon-group">
          <v-icon v-if="showCancel && data" @click="onClear" class="icon-close">
            mdi-close
          </v-icon>
          <v-icon
            v-show="!disabled && !readonly"
            :class="!selecting ? 'icon-down' : 'icon-up'"
            @click="!isMobile && onShowOption"
          >
            mdi-menu-down
          </v-icon>
        </div>
        <div class="data-list" v-if="selecting && items.length && itemText">
          <div
            class="data"
            v-for="(item, i) in items"
            :key="i"
            @click="selectItem(item)"
          >
            {{ multiLanguage ? $t(item[itemValue]) : item[itemText] }}
          </div>
        </div>
      </div>

      <div class="modal-mask" v-if="modalSelect && !readonly">
        <div class="modal-wrapper">
          <div class="modal-container container bounce-in">
            <div style="max-height: calc(100vh - 210px); overflow-y: auto">
              <div
                class="modal-data"
                v-for="(item, i) in items"
                :key="i"
                @click="selectItem(item)"
              >
                <div class="select-item">
                  <span class="name-item">{{
                    multiLanguage ? $t(item[itemValue]) : item[itemText]
                  }}</span>
                  <span
                    :class="[
                      'radio-check',
                      { 'radio-active': valueSelected == item[itemValue] },
                    ]"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MOBILE_LIST } from "@/common/constants.js";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
    value: {
      default: "",
    },
    listHeight: {
      type: Number,
    },
    closeIcon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    label: String,
    placeholder: String,
    required: {
      type: Boolean,
      default: false,
    },
    squareLabeled: {
      type: Boolean,
      default: false,
    },
    forcedSquareLabeled: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    multiLanguage: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    redBackground: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      data: this.items.find((o) => o[this.itemValue] === this.value)
        ? this.multiLanguage
          ? this.$t(
              this.items.find((o) => o[this.itemValue] === this.value)[
                this.itemValue
              ]
            )
          : this.items.find((o) => o[this.itemValue] === this.value)[
              this.itemText
            ]
        : null,
      selecting: false,
      oldValue: null,
      modalSelect: false,
      valueSelected: this.value,
    };
  },

  watch: {
    value() {
      this.data = this.items.find((o) => o[this.itemValue] === this.value)
        ? this.multiLanguage
          ? this.$t(
              this.items.find((o) => o[this.itemValue] === this.value)[
                this.itemValue
              ]
            )
          : this.items.find((o) => o[this.itemValue] === this.value)[
              this.itemText
            ]
        : null;
      this.selectItem(this.items.find((o) => o[this.itemValue] === this.value));
    },
  },

  computed: {
    isMobile() {
      return MOBILE_LIST.test(navigator.userAgent);
    },
  },

  methods: {
    selectItem(item) {
      if (this.oldValue !== item[this.itemValue]) {
        this.$emit("change", item[this.itemValue]);
      }
      this.data = this.multiLanguage
        ? this.$t(item[this.itemValue])
        : item[this.itemText];
      this.valueSelected = item[this.itemValue];
      this.oldValue = item[this.itemValue];
      this.selecting = false;
      if (this.isMobile && !this.disabled) {
        this.modalSelect = false;
      }
    },

    onOutside() {
      if (this.isMobile && !this.disabled) {
        this.modalSelect = false;
        return;
      }
      this.selecting = false;
    },

    onFocus() {
      if (this.isMobile && !this.disabled) {
        this.modalSelect = true;
        return;
      }
      this.selecting = true;
    },

    focus() {
      this.$refs.input.focus();
    },

    onShowOption() {
      this.selecting = !this.selecting;
    },

    onClear() {
      this.data = null;
      this.selecting = false;
      this.valueSelected = null;
      this.$emit("change", null);
    },
  },
};
</script>
