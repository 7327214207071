<template>
  <div class="take-photo-residence-card mt-3">
    <div class="text-danger fs-12" v-if="!getImageUrl(step)">
      <a class="btn-back" @click="backStep(step)">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div
      class="text-white-smoke text-center"
      v-if="!initCamera && !getImageUrl(step)"
    >
      <div class="portrait-mobile image mx-auto">
        <img
          v-if="step == 'residenceCardFront'"
          class="w-100"
          :src="require(`@/assets/img/residenceCardFront.png`)"
        />
        <img
          v-else-if="step == 'residenceCardBack'"
          class="w-100"
          :src="require(`@/assets/img/residenceCardBack.png`)"
        />
        <img
          v-else-if="step == 'designated'"
          class="w-100"
          :src="require(`@/assets/img/photo_example_designation.svg`)"
        />
        <img
          v-else-if="step == 'passport'"
          class="w-100"
          :src="require(`@/assets/img/photo_example_passport.svg`)"
        />
        <img
          v-else-if="step == 'studentCard'"
          class="w-100"
          :src="require(`@/assets/img/photo_example_student_ID.svg`)"
        />
        <img
          v-else
          class="w-100"
          :src="require(`@/assets/img/photo_example_other.svg`)"
        />
      </div>

      <p class="portrait-mobile box mx-auto pa-4">
        <span
          v-if="step == 'residenceCardFront'"
          v-html="$t('take the photo of your residence card frontside')"
        />
        <span
          v-else-if="step == 'residenceCardBack'"
          v-html="$t('take the photo of your residence card backside')"
        />
        <span
          v-else-if="step == 'designated'"
          v-html="$t('take the photo of the designation')"
        />
        <span
          v-else-if="step == 'passport'"
          v-html="$t('take the photo of the passport')"
        />
        <span
          v-else-if="step == 'studentCard'"
          v-html="$t('take the photo of the student ID')"
        />
        <span
          v-else
          v-html="
            fixCustomizedItemReason(
              $t('take the photo of the customized item'),
              step
            )
          "
        />
      </p>
      <div
        v-if="step == 'residenceCardFront' || step == 'residenceCardBack'"
        class="portrait-mobile d-flex justify-space-between mx-auto px-2 py-7"
      >
        <label for="file">
          <p
            class="button-danger"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
            v-html="$t('select a file')"
          ></p>
          <input
            ref="file"
            class="d-none"
            id="file"
            type="file"
            @change="uploadImage('file')"
          />
        </label>

        <div @click="initCamera = true">
          <p
            :class="{
              'py-1': $i18n.locale !== 'ja',
              'button-bg-danger':
                $i18n.locale !== 'pr' && $i18n.locale !== 'my',
              'button-bg-danger-flex':
                $i18n.locale === 'pr' || $i18n.locale === 'my',
            }"
            v-html="$t('take photo')"
          ></p>
        </div>
      </div>
      <div v-else class="justify-center px-2 py-7">
        <label for="file">
          <p
            v-html="$t('select a file') + '/' + $t('take photo')"
            class="button-bg-danger select-take-photo"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
          ></p>
          <input
            ref="file"
            class="d-none"
            id="file"
            type="file"
            @change="uploadImage('file')"
          />
        </label>

        <!-- 手元にない -->
        <div class="justify-center mt-4" v-if="isShowNoAtHandBtn(step)">
          <p
            class="button-danger"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
            v-html="$t('no at hand')"
            @click="nextScreen(step)"
          ></p>
        </div>
      </div>
    </div>

    <div :class="isMobile ? 'take-photo-section' : 'mt-7'">
      <take-photo-component
        v-if="!getImageUrl(step)"
        :init-camera="initCamera"
        @stop-camera="initCamera = false"
        @image-upload="setImageUpload($event, step)"
        @file-upload="setFileUpload($event, step)"
      >
        <div
          class="portrait-mobile box text-white-smoke text-center mx-auto"
          :class="isMobile ? 'guide-take-photo' : 'mt-5'"
        >
          <p class="pa-4" v-html="$t('within the frame')"></p>
        </div>
      </take-photo-component>
    </div>

    <div
      v-if="getImageUrl(step)"
      :class="{ 'mt-7': getImageUrl(step) }"
      class="text-center"
    >
      <image-preview-component
        :key="getImageUrl(step)"
        :url="getImageUrl(step)"
        :is-mobile="true"
        :max-width="343"
        :height="213"
      />
      <div class="portrait-mobile box text-white-smoke mx-auto mt-5">
        <p class="pa-4" v-html="$t('clear and readable')"></p>
      </div>
      <div
        class="portrait-mobile d-flex justify-space-between mx-auto px-2 py-7"
      >
        <p
          class="button-danger"
          :class="{ 'py-1': $i18n.locale !== 'ja' }"
          v-html="$t('retake')"
          @click="setImageUpload(null, step)"
        ></p>
        <p
          :class="{
            'py-1': $i18n.locale !== 'ja',
            'button-bg-danger': $i18n.locale !== 'ep' && $i18n.locale !== 'my',
            'button-bg-danger-flex':
              $i18n.locale === 'ep' || $i18n.locale === 'my',
          }"
          v-html="$t('next')"
          @click="nextStep(step)"
        ></p>
      </div>
    </div>

    <modal-component
      ref="modalLoading"
      :iconClose="false"
      :isClickOutside="false"
      :max-width="500"
    >
      <template #header> <span v-html="$t('upload image')" /> </template>
      <template #body>
        <div class="modal-body-data fs-14 mx-4 text-white-smoke">
          <div class="modal-body__img">
            <loading-component :loading="true" />
          </div>
          <h1 class="modal-body__title" v-html="$t('image uploading')" />
          <p v-html="$t('wait a moment')" />
        </div>
      </template>
    </modal-component>

    <modal-component
      :modalData="resultData"
      ref="resultModal"
      :max-width="500"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import EtcService from "@/services/etc.service";
import TakePhotoComponent from "@/components/TakePhotoComponent.vue";
import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import utilFunc from "@/common/utilFunc.js";
import { MOBILE_LIST, REQUIRED_ITEM } from "@/common/constants.js";

export default {
  name: "TakePhoto",

  props: ["residenceCard", "shopStaffAddInfo", "idAdmin"],

  components: {
    TakePhotoComponent,
    ImagePreviewComponent,
    LoadingComponent,
  },

  data() {
    return {
      initCamera: false,
      step: "residenceCardFront",
      stepList: [
        "residenceCardFront",
        "residenceCardBack",
        "designated",
        "passport",
        "studentCard",
      ],
      residenceCardFront: true,
      residenceCardBack: true,

      residenceCardFrontURL: "",
      residenceCardBackURL: "",
      designatedURL: "",
      passportURL: "",
      studentCardURL: "",

      residenceCardFrontFile: null,
      residenceCardBackFile: null,
      designatedFile: null,
      passportFile: null,
      studentCardFile: null,

      customizedItems: [],

      resultData: null,
    };
  },

  computed: {
    ...mapState({
      adminInfoState: (state) => JSON.parse(state.adminInfo) || null,
    }),

    designated() {
      return (
        this.residenceCard.visaTypeRegister.includes("Designated Activities") ||
        this.residenceCard.visaTypeRegister.includes("Specified Skilled Worker")
      );
    },

    passport() {
      return this.shopStaffAddInfo.passportRequirement !== REQUIRED_ITEM.HIDDEN;
    },

    studentCard() {
      return (
        this.shopStaffAddInfo.studentCardRequirement !== REQUIRED_ITEM.HIDDEN &&
        this.residenceCard.visaTypeRegister == "Student"
      );
    },

    isMobile() {
      return MOBILE_LIST.test(navigator.userAgent);
    },
  },

  created() {
    this.handleCustomizedItems();
  },

  methods: {
    //hander customized items to add screen upload image
    handleCustomizedItems() {
      this.customizedItems = this.shopStaffAddInfo.companySettings
        .filter(
          (o) => o.type == "image" && o.validation !== REQUIRED_ITEM.HIDDEN
        )
        .map((item) => ({
          idCompanySetting: item.id_company_setting,
          nameItem: item.name,
          valueItem: null,
          typeItem: item.type,
          file: null,
          validation: item.validation,
        }));

      this.customizedItems.forEach((o) => this.stepList.push(o.nameItem));
    },

    fixCustomizedItemReason(base, item) {
      if (base.match(/#customized_item#/)) {
        return base.replaceAll("#customized_item#", item);
      }

      return base;
    },

    isShowNoAtHandBtn(step) {
      if (this.stepList.includes(step)) {
        if (step === "passport") {
          return (
            this.shopStaffAddInfo.passportRequirement === REQUIRED_ITEM.OPTIONAL
          );
        }
        if (step === "studentCard") {
          return (
            this.shopStaffAddInfo.studentCardRequirement ===
            REQUIRED_ITEM.OPTIONAL
          );
        }
        if (step === "designated") {
          return false;
        }
      }

      const targetItem = this.customizedItems.filter((value) => {
        return value.nameItem === step;
      });

      if (0 in targetItem) {
        return targetItem[0].validation === REQUIRED_ITEM.OPTIONAL;
      }

      return false;
    },

    nextStep(currentStep) {
      this.$refs.modalLoading.open();

      let formData = new FormData();
      formData.append("file", this.getImageFile(currentStep));
      formData.append("type", `${currentStep}URL`);
      formData.append("idCompany", this.residenceCard.idCompany);
      formData.append("path", this.$route.path);
      if (this.idAdmin) {
        formData.append("idAdmin", this.idAdmin);
      }

      EtcService.postPictureUploadAny(formData)
        .then((res) => {
          if (this.checkCustomizedStep(currentStep)) {
            this.$emit("changeCustomizedResidenceCard", {
              nameItem: currentStep,
              valueItem: res.data,
            });
          } else {
            this.$emit("changeResidenceCard", {
              [currentStep + "URL"]: res.data,
            });
          }
          this.$emit("saveImageLocal", {
            [currentStep + "URL"]: this.checkCustomizedStep(currentStep)
              ? this.customizedItems.find((o) => o.nameItem == currentStep)
                  .valueItem
              : this[currentStep + "URL"],
          });
          this.nextScreen(currentStep);
        })
        .catch((err) => {
          this.resultData = err;
          this.$refs.resultModal.open();
        })
        .finally(() => {
          this.$refs.modalLoading.close();
        });
    },

    // next to screen
    nextScreen(currentStep) {
      let index = this.stepList.indexOf(currentStep);
      for (let i = index + 1; i < this.stepList.length; i++) {
        if (
          this[this.stepList[i]] ||
          this.checkCustomizedStep(this.stepList[i])
        ) {
          this.step = this.stepList[i];
          return;
        }
        if (
          !this[this.stepList[this.stepList.length - 1]] &&
          i == this.stepList.length - 1
        ) {
          this.$emit("changeComponent", "Signature");
          return;
        }
      }
      this.$emit("changeComponent", "Signature");
    },

    //back screeen
    backStep(currentStep) {
      if (this.initCamera) {
        this.initCamera = false;
        return;
      }

      if (this.step == "residenceCardFront") {
        this.$emit(
          "changeComponent",
          this.residenceCard.visaTypeRegister == "Student" &&
            (this.shopStaffAddInfo.graduationDateRequirement == "Required" ||
              this.shopStaffAddInfo.studentCardExpiredDateRequirement ==
                "Required")
            ? "GradDate"
            : "VisaStatus"
        );
        return;
      }

      let index = this.stepList.indexOf(currentStep) - 1;
      for (let i = index; i >= 0; i--) {
        if (
          this[this.stepList[i]] ||
          this.checkCustomizedStep(this.stepList[i])
        ) {
          this.step = this.stepList[i];
          return;
        }
      }
    },

    uploadImage(refs) {
      const file = this.$refs[refs].files[0];
      if (!file) return;

      const fileType = file.type;
      this.setFileUpload(file, this.step);

      if (fileType === "application/pdf") {
        utilFunc.convertPdfToBase64(file)
          .then((imageData) => {
            this.setImageUpload(imageData.toString(), this.step);
          })
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          this.setImageUpload(reader.result.toString(), this.step);
        };
        reader.readAsDataURL(file);
      }
    },

    checkCustomizedStep(currentStep) {
      return (
        currentStep != "residenceCardFront" &&
        currentStep != "residenceCardBack" &&
        currentStep != "designated" &&
        currentStep != "passport" &&
        currentStep != "studentCard"
      );
    },

    setFileUpload(file, currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        this.customizedItems.find((o) => o.nameItem == currentStep).file = file;
      } else {
        this[`${currentStep}File`] = file;
      }
    },

    getImageFile(currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        return this.customizedItems.find((o) => o.nameItem == currentStep).file;
      } else {
        return this[`${currentStep}File`];
      }
    },

    setImageUpload(imageUrl, currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        this.customizedItems.find((o) => o.nameItem == currentStep).valueItem =
          imageUrl;
      } else {
        this[`${currentStep}URL`] = imageUrl;
      }
    },

    getImageUrl(currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        return this.customizedItems.find((o) => o.nameItem == currentStep)
          .valueItem;
      } else {
        return this[`${currentStep}URL`];
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/abstracts/mixin.scss";
@import "@/assets/scss/abstracts/variable.scss";

.take-photo-residence-card {
  .take-photo-section {
    @include respond(portrait-mobile) {
      margin-top: 2.7rem;
    }
    margin-top: -1.6rem;

    .guide-take-photo {
      @include respond(portrait-mobile) {
        margin: 2rem;
      }
    }
  }

  .button-danger,
  .button-bg-danger {
    padding: 0.8rem 0.4rem 0rem 0.4rem;
    font-size: 1.6rem;
    width: 15rem;
    cursor: pointer;
  }

  .select-take-photo {
    width: 25rem !important;
  }

  .box {
    background: $color-grey-light-v3;
    border-radius: 0.8rem;
    line-height: 2;
  }
}
</style>
